<template>
  <TransitionRoot appear :show="$visible" as="template">
    <Dialog as="div" class="relative z-30 w-full">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full justify-center text-center sm:items-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="min-h-full w-full transform bg-white text-left align-middle shadow-xl transition-all sm:max-w-2xl lg:max-w-4xl md:rounded-md"
            >
              <button
                class="absolute right-0 top-0 p-2"
                @click="cerrarModalDisponibilidad"
              >
                <svg
                  class="h-6 w-6 text-gray-400 md:hover:text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <!-- CONTENIDO -->
              <div class="mt-4 p-4 sm:p-7" v-if="$producto && item">
                <h1 class="text-xl leading-7 font-semibold text-center mb-4">
                  Producto sin stock, pero tenemos soluciones
                </h1>
                <CartItemVue
                  class="max-w-80 mx-auto mb-6 md:mb-8"
                  :item="item"
                  agotado
                />
                <div class="flex flex-col md:flex-row-reverse gap-6">
                  <div class="md:w-7/12 lg:w-5/12">
                    <h2 class="text-lg leading-7 font-bold mb-2">
                      Producto disponible en
                    </h2>
                    <h3
                      class="text-lg text-green-500 leading-7 font-medium mb-3"
                    >
                      Tiendas
                    </h3>
                    <ul class="list-disc list-inside">
                      <li
                        v-for="despacho in despachosDisponibles"
                        :key="despacho.bodegaId"
                      >
                        {{ despacho.nombre }} (stock:
                        <span class="font-semibold" v-if="!loading">{{
                          despacho.stock
                        }}</span>
                        <div
                          class="h-5 w-4 bg-gray-50 animate-pulse rounded inline-block -mb-1"
                          v-else
                        ></div>
                        )

                        <div
                          class="text-xs text-gray-400 leading-4 font-normal ml-6 mb-3"
                          v-if="despacho.direccion"
                        >
                          {{ despacho.direccion }}
                        </div>
                      </li>
                    </ul>
                    <p class="text-xs text-gray-400 leading-4 font-normal">
                      *stock en vivo, podría agotarse
                    </p>
                  </div>
                  <div class="md:w-5/12 lg:w-7/12 md:border-r md:pr-4 min-h-96">
                    <h2 class="text-lg leading-7 font-bold mb-3">
                      Productos similares
                    </h2>
                    <div
                      v-if="loading"
                      class="flex justify-center items-center h-full"
                    >
                      <Loader class="mb-12" />
                    </div>
                    <p
                      v-else-if="!productosSimilares.length"
                      class="text-lg leading-7 font-normal text-gray-500"
                    >
                      Aun no tenemos productos similares para este producto,
                      pero puedes encontrarlo en otra tienda.
                    </p>
                    <Flicking
                      v-else
                      :options="options"
                      :plugins="pluginsCarruselProductosSimilares"
                      class="px-2 md:mx-auto -mx-4"
                      ref="carruselProductosSimilares"
                    >
                      <TarjetaProducto
                        class="w-40 sm:w-52 mb-8"
                        :class="
                          key === 0
                            ? 'ml-0 mr-2 md:mr-3 lg:mr-4'
                            : 'mx-2 md:mx-3 lg:mx-4'
                        "
                        truncateName
                        :producto="producto"
                        v-for="(producto, key) in productosSimilares"
                        :key="key"
                      />
                      <template #viewport>
                        <Paginador
                          class="-bottom-2"
                          :carrusel="carruselProductosSimilares"
                          :nombre-paginador="
                            nombrePaginadorCarruselProductosSimilares
                          "
                          :background="false"
                        />
                      </template>
                    </Flicking>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup lang="ts">
  import { useStore } from "@nanostores/vue";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
  } from "@headlessui/vue";
  import {
    bodegasRetiro,
    ventanaActiva,
    bodegaActivaId,
  } from "@stores/despacho";
  import {
    productoModalDisponibilidad,
    modalDisponibilidad,
    cerrarModalDisponibilidad,
  } from "@stores/app";
  import { ref, watch } from "@vue/runtime-core";
  import { getStockBodegasProducto } from "@api/client/productos";
  import { crearItem } from "@stores/carro";
  import type { Item, Producto } from "@lib/interfaces";
  import CartItemVue from "@components/carro/CartItem.vue";
  import TarjetaProducto from "@components/producto/TarjetaProducto.vue";
  import Flicking from "@egjs/vue3-flicking";
  import Loader from "@components/Loader.vue";
  import Paginador from "@ui/Paginador.vue";
  import { plugins } from "@lib/bullets";

  interface DespachoDisponible {
    bodegaId: number;
    nombre: string;
    stock: number;
    direccion: string | undefined;
  }

  const $visible = useStore(modalDisponibilidad);
  const $producto = useStore(productoModalDisponibilidad);
  const $bodegasRetiro = useStore(bodegasRetiro);
  const $ventanaActiva = useStore(ventanaActiva);
  const $bodegaActivaId = useStore(bodegaActivaId);
  const despachosDisponibles = ref<DespachoDisponible[]>([]);
  const disponibilidad = ref(true);
  const item = ref<Item>();
  const productosSimilares = ref<Producto[]>([]);
  const loading = ref(false);
  const carruselProductosSimilares = ref<Flicking>();
  const nombrePaginadorCarruselProductosSimilares =
    "paginador-carrusel-productos-similares";
  const pluginsCarruselProductosSimilares = plugins(
    nombrePaginadorCarruselProductosSimilares,
    false,
  );

  const options = {
    align: "prev",
    resizeDebounce: 120,
    bound: true,
    preventDefaultOnDrag: true,
  };

  const getStocksTiendas = async () => {
    if (!$producto.value) return;
    if (!$bodegaActivaId.value) return;
    if (!$ventanaActiva.value) return;
    loading.value = true;
    item.value = crearItem($producto.value, 0, false);
    item.value.precio_oferta = 0;
    const bodegasIds = $bodegasRetiro.value.map((bodega) => bodega.id);
    despachosDisponibles.value = $bodegasRetiro.value.map((bodega) => ({
      bodegaId: bodega.id,
      nombre: "Tienda " + bodega.nombre,
      stock: 0,
      direccion: bodega.direccion,
    }));
    const response = await getStockBodegasProducto(
      $producto.value.id,
      bodegasIds,
      $bodegaActivaId.value,
      $ventanaActiva.value.id,
    );
    if (response.estado == "error") return (disponibilidad.value = false);
    productosSimilares.value = response.productosSimilares;
    for (const bodegaId in response.stockBodegas) {
      const despacho = despachosDisponibles.value.find(
        (despacho) => despacho.bodegaId == parseInt(bodegaId),
      );
      if (despacho) despacho.stock = response.stockBodegas[bodegaId];
    }
    loading.value = false;
  };

  watch($producto, async () => {
    getStocksTiendas();
  });
</script>
